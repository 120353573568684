import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from '../App';
import moment from 'moment'
import $ from 'jquery'

const mapDomain = 'http://localhost:3000/map#/directions?to=&from=';

const MapScreen = (props) => {

  const myStores = useContext(StoreContext);

  const updateReloadTime = () => {
    setTimeout(()=>{
      //let formatted = moment(new Date()).format("hh:mm:ss");
      localStorage.setItem('mx-map-last-active', moment().unix() );
    }, 50)
  };

  const checkUrlInterVal = () => {
    setInterval(()=>{
      console.log ('-->check interval init' );

      try {
        let fromFromStorage = localStorage.getItem('mx-map-default-location');

        if(!fromFromStorage)
          return;

        fromFromStorage = JSON.parse(fromFromStorage);
        let from = window.location.href.split('from=');
        if(fromFromStorage.value === from[1])
        {
          console.log ('-->ignore same from' );
          return;
        }

        let lastActive = localStorage.getItem('mx-map-last-active');
        if(!lastActive)
          return;

        let storageTime = moment.unix(lastActive);
        let nextTime = storageTime.add(5, 'minutes').unix();
        if(moment().unix() < nextTime  )
        {
          console.log('ignore by time');
          return;
        }

        console.log ('-->will reload' );
        localStorage.setItem('mx-map-last-active', '');
        let defLocation = localStorage.getItem("mx-map-default-location");
        defLocation = JSON.parse(defLocation);
        window.location.href = myStores.mapStore.mapDomain + defLocation.value

      } catch (err) {
        console.log ('-->e', err );
      };

    }, 3000);
    try {

    } catch (err) {

    }
  };

  const checkIsUrlChange = () => {
    setInterval(()=>{
      try
      {
        setTimeout(()=>{
          let profileId = window.location.href.split('location=');
          if(profileId[1])
          {
            let defLocation = localStorage.getItem("mx-map-default-location");
            defLocation = JSON.parse(defLocation);
            //let url = 'http://localhost:3000/map#/directions?';
            let url = 'https://map.ribf.app/map#/directions?';

            console.log('profileId=>',profileId[1]);
            window.location.href = `${url}to=${profileId[1]}&from=${defLocation.value}`
          }
        }, 0)
      }
      catch (err)
      {

      }
    }, 2000)
  };

  useEffect(() => {

    //return () => {

      $('#mappedin-map').show();
      checkUrlInterVal();
      checkIsUrlChange();
      setTimeout( () => {
        console.log('map js done');
        document.addEventListener("click", updateReloadTime) ;
        document.addEventListener("keypress", updateReloadTime);
        document.addEventListener("touchmove", updateReloadTime);
        document.addEventListener("touchstart", updateReloadTime);
        document.addEventListener("mousedown", updateReloadTime);
      }, 100)

    //}


  });

  return (
      <span></span>
  )
};

export default MapScreen;
