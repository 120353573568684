import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from '../App';
import $ from "jquery";
import { getVenue } from '@mappedin/mappedin-js';
import Select from 'react-select'



const HomeScreen = (props) => {

  const navigate = useNavigate();

  const [state, setState] = useState({
    busy: true,
    venueData: [],
    init: false
  });

  const myStores = useContext(StoreContext);

  const fetchData = async () => {
    if(state.init)
      return;

    setTimeout(async ()=>{
      setState(state=>({...state, busy: true, init: true}));
      console.log ('-->load data' );
      const credentials = myStores.mapStore.credentials;
      const venueData = await getVenue(credentials);
      console.log ('-->venueData', venueData );

      let suggest = [];
      venueData.locations.map((item, index) => {
        suggest.push({ value: item.id, label: item.name });
      });

      setState(state=>({...state, venueData: suggest, busy: false, init: true}));
    }, 100)
  };

  const onSelectDefaultLocation = (e) => {
    localStorage.setItem("mx-map-default-location", JSON.stringify(e));
    //navigate(`/map#/directions?to=&from=${e.value}`)
    window.location.href = myStores.mapStore.mapDomain + e.value
  };

  useEffect(() => {

    console.log('uss11');


      console.log('return');
      try {

        let fromFromStorage = localStorage.getItem('mx-map-default-location');
        fromFromStorage = JSON.parse(fromFromStorage);
        if(fromFromStorage)
          window.location.href = myStores.mapStore.mapDomain + fromFromStorage.value

        console.log('check');
      } catch (err) {
        console.log('err');
      }

      console.log('run fetch');
      fetchData();

  }, []);

  return (
      <div>

        <br />
        <div style={{
          marginTop: 20,
          marginLeft: 20,
          width: 300
        }}>
          {state.busy &&
            <p>loading ....</p>
          }
        {!state.busy &&
            <div>
              <p>Please select default location</p>
              <Select options={state.venueData} onChange={onSelectDefaultLocation}/>
            </div>
        }
        </div>


        {/*<select>
          <options> location 1</options>
          <options> location 2</options>
          <options> location 3</options>
        </select>*/}
      </div>
  )
};

export default HomeScreen;
