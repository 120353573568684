

class MapStore {
  constructor(){
    this.credentials = {
      "clientId": "62fd488d5550ac001c7d3b99",
      "clientSecret": "9ZWBXsaL2RTyV85fngOe0jsDOUZMIIZbvptcb67UVk7HsHLC",
      "venue": "riyadh-international-book-fair",
      "lang": "en"
    };
    //this.mapDomain = 'http://localhost:3000/map#/directions?to=&from='
    this.mapDomain = 'https://map.ribf.app/map#/directions?to=&from='
  }
}

export default MapStore;
