
import { createContext } from "react";
import Store from "./stores";
import RootNavigator from "./navigation/RootNavigator";
export const StoreContext = createContext({});

function App() {
  return (
      <StoreContext.Provider value={Store}>
        <RootNavigator />
      </StoreContext.Provider>
  );
}

export default App;
