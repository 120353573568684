import { createContext } from "react";

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import HomeScreen from '../screens/HomeScreen'
import MapScreen from '../screens/MapScreen'

const RootNavigator = () => {
  return (
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/map" element={<MapScreen />} />
          </Routes>
        </BrowserRouter>
  )
};


export default RootNavigator;
